import React, { useContext, useState, useEffect } from "react";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";

import { CurrentUserContext } from "../providers/auth";
import { MapEditState } from "../helpers/mapEditState";
import { JobTypes } from "../helpers/const";

import * as Styles from "./header.module.css";

const Header = () => {
    const currentUser = useContext(CurrentUserContext);

    const isLoggedIn = currentUser.getUser() ? true : false;

    const {
        CPAS_LOGO_URI,
        CPAS_LOGO_LABEL,
        CPAS_VIS_URI,
    } = useStaticQuery(graphql`
        query CPASVisURIQuery {
            site {
                siteMetadata {
                    CPAS_LOGO_URI
                    CPAS_LOGO_LABEL
                    CPAS_VIS_URI
                }
            }
        }
    `).site.siteMetadata;

    const onSignOut = () => {
        if (!MapEditState.isEdited()) {
            currentUser.signOut();
        } else {
            if (MapEditState.confirmDialog()) {
                currentUser.signOut();
            }
        }
    };

    return (
        <header style={{ zIndex: "1" }}>
            <nav className={Styles.nav}>
                <div className={`${Styles.brand} relative`}>
                    {CPAS_LOGO_URI.trim().length > 0 ? (
                        <a href={CPAS_LOGO_URI} title="CPAS Homepage">
                            <div className={Styles.logo} />
                        </a>
                    ) : (
                        <div className={Styles.logo} />
                    )}
                    {CPAS_LOGO_LABEL.trim().length > 0 && (
                        <div className={Styles.label}>{CPAS_LOGO_LABEL}</div>
                    )}
                </div>
                <NavLinks
                    CPAS_VIS_URI={CPAS_VIS_URI}
                    currentUser={currentUser}
                />

                <div className={Styles.flexible} />
                <AccountAction
                    isLoggedIn={isLoggedIn}
                    onSignOut={() => onSignOut()}
                />
            </nav>
        </header>
    );
};

export default Header;

const goToPage = (e, page) => {
    e.preventDefault();
    if (!MapEditState.isEdited()) {
        navigate(page);
    } else {
        if (MapEditState.confirmDialog()) {
            navigate(page);
        }
    }
};

const NavLinks = ({ CPAS_VIS_URI, currentUser }) => {
    const [accessToken, setAccessToken] = useState(null);

    const isLoggedIn = currentUser.getUser() ? true : false;

    useEffect(() => {
        let isSubscribed = true;
        if (isLoggedIn) {
            currentUser.getNewAccessToken().then((accessToken) => {
                // Prevent set state on component unmount
                if (isSubscribed) {
                    setAccessToken(accessToken);
                }
            });
        }
        return () => (isSubscribed = false);
    }, [currentUser, isLoggedIn]);

    return isLoggedIn ? (
        <>
            <Link
                to="/meshspec"
                onClick={(e) => {
                    goToPage(e, "/meshspec");
                }}
                getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                        ? {
                              className: Styles.active,
                          }
                        : null;
                }}
            >
                My Specs
            </Link>
            <Link
                to={"/job/" + JobTypes.meshgen}
                onClick={(e) => {
                    goToPage(e, "/job/" + JobTypes.meshgen);
                }}
                getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent
                        ? {
                              className: Styles.active,
                          }
                        : null;
                }}
            >
                My Jobs
            </Link>
            {accessToken ? (
                <>
                    <button
                        style={{ color: "#fff" }}
                        onClick={(e) => {
                            e.preventDefault();
                            currentUser
                                .getNewAccessToken()
                                .then((accessToken) => {
                                    window.open(
                                        `${CPAS_VIS_URI}/hub/login?access_token=${accessToken}`
                                    );
                                });
                        }}
                    >
                        Visualization&nbsp;
                        <i className="fas fa-external-link-alt"></i>
                    </button>
                </>
            ) : null}
        </>
    ) : null;
};

const AccountAction = ({ isLoggedIn, onSignOut }) =>
    isLoggedIn ? (
        <>
            <Link
                to="/purchase/currentSubscription"
                onClick={(e) => {
                    goToPage(e, "/purchase/currentSubscription");
                }}
            >
                <i className="fas fa-file-invoice-dollar fa-fw mr-1" />
                My Subscription
            </Link>
            <Link
                to="/account"
                onClick={(e) => {
                    goToPage(e, "/account");
                }}
            >
                <i className="fas fa-user fa-fw mr-1" />
                My Account
            </Link>
            <a
                href="/signout"
                onClick={(e) => {
                    e.stopPropagation();
                    onSignOut();
                }}
            >
                <i className="fas fa-sign-out-alt fa-fw mr-1" />
                Sign Out
            </a>
        </>
    ) : (
        <Link to="/signin">
            <i className="fas fa-sign-in-alt fa-fw mr-1" />
            Sign In
        </Link>
    );
