import React, { useState, useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import Amplify, { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

export const CurrentUserContext = React.createContext(null);
export const ATTR_RECEIVE_NEWS = "custom:receive_news";
export const ATTR_ACCEPTED_TERMS_TIME = "custom:agreement_at";

export const CurrentUserProvider = ({ children }) => {
    const {
        COGNITO_DOMAIN,
        COGNITO_REGION,
        COGNITO_USERPOOL_ID,
        COGNITO_CLIENT_ID,
        COGNITO_REDIRECT_SIGNIN_URI,
        COGNITO_REDIRECT_SIGNOUT_URI,
    } = useStaticQuery(graphql`
        query CognitoSettingsQuery {
            site {
                siteMetadata {
                    COGNITO_DOMAIN
                    COGNITO_REGION
                    COGNITO_USERPOOL_ID
                    COGNITO_CLIENT_ID
                    COGNITO_REDIRECT_SIGNIN_URI
                    COGNITO_REDIRECT_SIGNOUT_URI
                }
            }
        }
    `).site.siteMetadata;

    Amplify.configure({
        Auth: {
            region: COGNITO_REGION,
            userPoolId: COGNITO_USERPOOL_ID,
            userPoolWebClientId: COGNITO_CLIENT_ID,
            oauth: {
                domain: COGNITO_DOMAIN,
                scope: [
                    "email",
                    "profile",
                    "openid",
                    "aws.cognito.signin.user.admin",
                ],
                redirectSignIn: COGNITO_REDIRECT_SIGNIN_URI,
                redirectSignOut: COGNITO_REDIRECT_SIGNOUT_URI,
                responseType: "code",
            },
        },
    });

    const [user, setUser] = useState(null);

    const currentUser = {
        getIdToken: async () =>
            Auth.currentSession()
                .then((t) => t.idToken.jwtToken)
                .catch(() => null),
        getAccessToken: async () =>
            Auth.currentSession()
                .then((t) => t.accessToken.jwtToken)
                .catch(() => null),
        getNewAccessToken: async () =>
            Auth.currentSession()
                .then((t) => {
                    return t.refreshToken;
                })
                .then((token) => {
                    return new Promise((resolve, reject) => {
                        Auth.currentAuthenticatedUser().then((user) =>
                            user.refreshSession(token, (err, session) => {
                                if (err) {
                                    reject(err);
                                }
                                resolve(session);
                            })
                        );
                    });
                })
                .then((session) => {
                    return session.accessToken.jwtToken;
                })
                .catch((err) => {
                    return null;
                }),
        currentAuthenticatedUser: () =>
            Auth.currentAuthenticatedUser({ bypassCache: true }),
        getUser: () => user,
        signInWithGoogle: () => Auth.federatedSignIn({ provider: "Google" }),
        signIn: (email, password) => Auth.signIn(email, password),
        signUp: (email, password, attrs) =>
            Auth.signUp({
                username: email,
                password: password,
                attributes: attrs,
            }),
        signOut: () => Auth.signOut(),
        forgotPassword: (email) => Auth.forgotPassword(email),
        resetPassword: (email, code, newPassword) =>
            Auth.forgotPasswordSubmit(email, code, newPassword),
        updateUserAttributes: (user, attributes) =>
            Auth.updateUserAttributes(user, attributes),
        changePassword: (oldPassword, newPassword) =>
            Auth.changePassword(user, oldPassword, newPassword),
    };

    useEffect(() => {
        const listener = ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    break;
                case "signOut":
                    setUser(null);
                    break;
                case "configured":
                    break;
                default:
                    break;
            }
        };

        Hub.listen("auth", listener);

        Auth.currentAuthenticatedUser()
            .then((user) => setUser(user))
            .catch(() => setUser(null));

        return () => {
            Hub.remove("auth", listener);
        };
    }, []);

    return (
        <CurrentUserContext.Provider value={currentUser}>
            {children}
        </CurrentUserContext.Provider>
    );
};
