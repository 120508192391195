export const MapEditState = {
    reset: () => {
        window._meshMapIsEdited = false;
    },

    set: () => {
        window._meshMapIsEdited = true;
    },

    isEdited: () => {
        return window._meshMapIsEdited;
    },

    confirmDialog: () => {
        const r = window.confirm(
            "Mesh editing haven't been saved. Are you sure to leave the page?"
        );
        if (r === true) {
            MapEditState.reset();
            return true;
        }
        return false;
    },
};
