import React from "react";
import PropTypes from "prop-types";

import { CurrentUserProvider } from "../providers/auth";

import Header from "./header";

import * as Styles from "./layout.module.css";
import "../styles/global.css";

const Layout = ({ children }) => {
    return (
        <CurrentUserProvider>
            <div style={{ minHeight: "100vh", marginBottom: "-2rem" }}>
                <Header />
                <main className="h-full pb-8">{children}</main>
            </div>
            <footer className={Styles.footer}>
                Copyright © 2019-{new Date().getFullYear()} ClusterTech Limited.
                All rights reserved.
            </footer>
        </CurrentUserProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
